@import "../../utils/common.scss";

.container {
  @extend .flexRow;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  height: 100px;
  width: -moz-fit-content;
  width: fit-content;
  .imageContainer {
    width: 100px;
    height: 100px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .textContainer {
    height: 100%;
    padding: 10px;
    @extend .flexCol;
    justify-content: flex-start;
    align-items: flex-start;
    p {
      padding: 3px;
      color: #555555;
      .fieldAnswerTextual {
        color: #f7931e;
        font-weight: 500;
      }
      .timeTag {
        background-color: #61b4f1;
        padding: 0.3rem 0.8rem;
        color: white;
        border-radius: 20px;
      }
    }
  }

  @media screen and (max-width: 786px) {
    .imageContainer {
      width: 80px;
      height: 80px;
    }
  }
  @media screen and (max-width: 400px) {
    width: 100%;
    .textContainer {
      p {
        span:nth-of-type(1) {
          display: none;
        }
      }
    }
  }
}
