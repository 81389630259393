* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}
body {
  font-size: 16px;
}
:root {
  --clr-primary: #61b4f1;
  --clr-primary-disabled: #bbe3ff;

  --clr-success: #55bc7e;
  --clr-success-disabled: #94c9a9;

  --clr-warning: #f8ba1c;
  --clr-warning-disabled: #fce7b2;

  --clr-error: #fc5f5f;
  --clr-error-disabled: #f79090;

  --fixed-width: 80%;
}

@media screen and (max-width: 1400px) {
  :root {
    --fixed-width: 90%;
  }
}

@media screen and (max-width: 500px) {
  :root {
    --fixed-width: 95%;
  }
}
