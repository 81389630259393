@import "../../utils/common.scss";

.container {
  .logoSection {
    padding: 10px calc((100% - var(--fixed-width)) / 2);

    @extend .flexRow;
    .moreInfoTest {
      margin-left: auto;
      button {
        margin: 5px;
      }
    }
  }
  @media screen and (max-width: 786px) {
    .logoSection {
      flex-direction: column;
      align-items: center;
      .moreInfoTest {
        margin-left: 0;
        margin-top: 1rem;
      }
    }
  }
  @media screen and (max-width: 420px) {
    .logoSection {
      .moreInfoTest {
        width: 100%;
        button {
          width: 100%;
          margin: 0.5rem 0;
        }
      }
    }
  }
}
