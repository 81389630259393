@import "../../utils/common.scss";

.container {
  background-color: white;

  .mainContainer {
    @extend .flexRow;

    max-width: var(--fixed-width);
    width: 90%;
    margin: 2rem auto;
    align-items: flex-start;
    .maxWidthLeftContainer {
      width: 100% !important;
    }
    .leftContainer {
      width: 60%;
      .actionButtonsContainer {
        margin: 5px 0px;
        display: flex;
        > button {
          margin-right: 20px;
        }
      }
      .navigationBtnsContainer {
        @extend .flexRow;
        justify-content: space-between;
        .navigationBtns {
          // margin: 0px 5px;
          button:nth-child(2) {
            margin-left: 10px;
          }
        }
      }
    }
    .hideRightContainer {
      width: 10% !important;
      height: 53vh;
      @extend .flexRow;
      .expandRight {
        transform: rotate(180deg);
        margin: 10px;
        cursor: pointer;
        border-radius: 50%;
        padding: 5px 7px;
        background-color: white;
        border: 1px solid #555555;
      }
    }
    .rightContainer {
      width: 40%;
      @extend .flexRow;
      .expandRight {
        margin: 10px;
        cursor: pointer;
        border-radius: 50%;
        padding: 5px 7px;
        background-color: white;
        border: 1px solid #555555;
      }
      .mainContent {
        min-width: 250px;
        width: 100%;
        margin: 0;
      }
      .questionButtonsContainer {
        height: 40vh;
        overflow-y: auto;

        button {
          width: 35px;
          height: 35px;
          margin: 3px;
          cursor: pointer;
        }
        .notVisited {
          padding: 0px;
          background: rgba(0, 0, 0, 0.04);
          border: 2px solid rgba(0, 0, 0, 0.3);
          border-radius: 3px;

          span {
            text-align: center;
          }
        }

        .notAnswered,
        .answered {
          background: red;
          clip-path: polygon(0 0, 100% 25%, 100% 76%, 0% 100%);
          color: white;
          border: none;
        }

        .answered {
          background: green;
        }

        .markedForReview,
        .answeredAndMarkedForReview {
          background: linear-gradient(
            129deg,
            rgba(2, 0, 36, 1) 0%,
            rgba(131, 96, 187, 1) 0%,
            rgba(77, 32, 146, 1) 100%
          );
          color: white;
          border: none;
          border-radius: 50%;
        }

        .answeredAndMarkedForReview {
          position: relative;
          &::after {
            content: "";
            background: #5cb85c;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            position: absolute;
            bottom: 0;
            right: 0;
            margin-bottom: 2px;
            margin-right: 3px;
          }
        }
      }
    }
    @media screen and (max-width: 786px) {
      flex-direction: column;
      main,
      aside {
        width: 100% !important;
        .expandRight {
          display: none;
        }
      }
      .rightContainer {
        margin-top: 2rem;
      }
    }
  }

  .questions {
    margin-top: 0.5rem;
    .question {
      margin: 1rem 0;
      padding: 1rem 0;
      @extend .flexCol;
      align-items: flex-start;
      .questionWrapper {
        align-items: flex-start;

        @extend .flexRow;
        > span {
          margin-top: 0.2rem;
        }
        p {
          margin-left: 0.2rem;
        }
      }
      .optionWrapper {
        @extend .flexCol;
        justify-content: flex-start;
        padding: 0.5rem 1rem;
        width: 100%;
        .option {
          @extend .flexRow;
          width: 100%;

          margin-right: 1rem;
          margin-top: 0.5rem;
          > span {
            margin-top: 0.2rem;
            font-size: 1rem;
          }
          p {
            margin-left: 0.5rem;
          }
        }
      }
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
}
