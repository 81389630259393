@import "../../utils/common.scss";

.container {
  width: 100%;
  padding: 1.5rem calc((100% - var(--fixed-width)) / 2);
  background-color: rgb(235, 235, 235);
  @extend .flexRow;

  select {
    margin-left: auto;
    font-size: 0.8rem;
    width: 200px;
    padding: 10px;
    background-color: white;
    outline: none;
    border: 2px solid rgb(201, 201, 201);
    &,
    option {
      cursor: pointer;
    }
  }
  @media screen and (max-width: 786px) {
    flex-direction: column;
    align-items: center;

    select {
      margin-left: 0;
      margin-top: 1rem;
    }
  }
  @media screen and (max-width: 400px) {
    select {
      width: 100%;
    }
  }
}
