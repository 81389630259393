@import "../../utils/common.scss";

.btn {
  color: white;
  padding: calc(0.5rem + 2px) 1rem;
  border: none;
  outline: none;
  border-radius: 0.5rem;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 0.6rem;
  margin-right: 0.2rem;
  // > span {
  //   @extend .flexRow;
  //   span {
  //     margin-right: 0.3rem;
  //   }
  // }
}

.clrPrimary {
  background: var(--clr-primary);
  &:disabled {
    background: var(--clr-primary-disabled);
    cursor: not-allowed;
  }
}
.clrError {
  background: var(--clr-error);
  &:disabled {
    background: var(--clr-error-disabled);
    cursor: not-allowed;
  }
}
.clrWarning {
  background: var(--clr-warning);
  &:disabled {
    background: var(--clr-warning-disabled);
    cursor: not-allowed;
  }
}
.clrSuccess {
  background: var(--clr-success);
  &:disabled {
    background: var(--clr-success-disabled);
    cursor: not-allowed;
  }
}
