@import "../../utils/common.scss";

.container {
  @extend .flexCol;
  width: 100%;
  min-height: 100vh;
  .imageContainer {
    padding: 20px;
    margin: auto;
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
  }

  .header {
    width: 100%;
  }
  .main {
    // text-align: center;
    width: var(--fixed-width);
    margin: 2rem auto;
    padding: 1rem 0;

    h3 {
      font-size: 1.3rem;
      font-weight: 500;
      text-align: center;
    }

    .instructionSection {
      margin: 2rem 0;
      h4 {
        font-size: 1.2rem;
        font-weight: 400;
        text-decoration: underline;
      }
      ol {
        margin-top: 1rem;
        li {
          margin: 0.5rem 0;
          margin-left: 1rem;
          color: rgba(0, 0, 0, 0.6);

          span:nth-child(1) {
            @extend .flexRow;
            flex-wrap: wrap;
          }
        }
      }

      .arrowUp,
      .arrowDown {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: var(--clr-primary);
        @extend .flexRow;
        margin: 0 0.5rem;
        img {
          width: 60%;
          margin: auto;
        }
      }
      .arrowUp img {
        transform: rotate(180deg);
      }
    }
  }
  .continueBtn {
    display: block;
    margin-top: 3rem;
    width: 100%;
    padding: 1rem 0;
    span {
      justify-content: center;
      font-weight: 600;
      font-size: 1rem;
    }
  }
  .warning {
    color: rgb(255, 68, 68);
    margin: 2rem 0;
  }
  input[type="checkbox"] {
    cursor: pointer;
  }
  label {
    color: rgba(0, 0, 0, 0.6);
    cursor: pointer;
  }
}
.notVisitedBtn {
  width: 30px;
  height: 30px;
  background: #d0d0d7;
  border: 1px solid black;
  border-radius: 5px;
  display: inline-block;
  margin-right: 1rem;
}

.notAnswered {
  height: 30px;
  width: 30px;
  background: red;
  clip-path: polygon(0 0, 100% 25%, 100% 76%, 0% 100%);
  display: inline-block;
  margin-right: 1rem;
  padding: 5px;
  color: #fff;
}

.answered {
  height: 30px;
  width: 30px;
  background: green;
  clip-path: polygon(0 0, 100% 25%, 100% 76%, 0% 100%);
  display: inline-block;
  margin-right: 1rem;
  padding: 5px;
  color: #fff;
}

.markForReview {
  color: #fff;

  width: 30px;
  height: 30px;
  border-radius: 50%;

  background: rgb(2, 0, 36);
  background: linear-gradient(
    129deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(131, 96, 187, 1) 0%,
    rgba(77, 32, 146, 1) 100%
  );
  padding: 5px 7px;
  display: inline-block;
  margin-right: 1rem;
}

.answeredAndMarkForReview {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    129deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(131, 96, 187, 1) 0%,
    rgba(77, 32, 146, 1) 100%
  );
  padding: 5px 7px;
  color: #fff;
  display: inline-block;
  margin-right: 1rem;
  position: relative;
  &::after {
    content: "";
    background: #5cb85c;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    bottom: 0;
    right: 0;
    margin-bottom: 2px;
    margin-right: 3px;
  }
}
