@import "../../utils/common.scss";

.container {
  scroll-behavior: smooth;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  padding: 2%;
  padding: 10px 30px;
  height: 50vh;
  max-height: 500px;
  overflow: auto;
  border: 1px solid rgb(216, 216, 216);

  a {
    margin-left: auto;
    background-color: var(--clr-primary);
    border-radius: 50%;
    padding: 5px 7px;
  }
  .header {
    margin: 0.8rem 0rem;
    @extend .flexRow;
    position: sticky;
    top: 0;
    background-color: white;
  }
  .divider {
    width: 100%;
    background-color: rgb(218, 218, 218);
    height: 1px;
    margin: 3% 0px;
  }
  .main {
    p {
      color: #555555;
      line-height: 24px;
    }
  }
  .options {
    padding: 10px 0;
    li {
      @extend .flexRow;
      margin: 1rem 0;
      input {
        cursor: pointer;
      }
      label {
        padding-left: 10px;
        cursor: pointer;
      }
    }
  }
  .buttonContainer {
    @extend .flexRow;
    a {
      img {
        transform: rotate(180deg);
      }
    }
  }
}
