@import "../../utils/common.scss";

.container {
  @extend .flexCol;
  width: 100%;
  height: 100vh;
  // section {
  //   width: 100%;
  //   @extend .flexRow;
  //   .imageContainer {
  //     padding: 20px;
  //     margin: auto;
  //     width: -moz-fit-content;
  //     width: -webkit-fit-content;
  //     width: fit-content;
  //   }
  // }
  .header {
    width: 100%;
  }
  .card {
    padding: 1.5rem;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
    margin-top: 10%;
    border: 1px solid #c2c2c2;
    border-radius: 0.5rem;

    .inputField {
      margin: 1rem 0;
      input {
        width: 100%;
      }
    }

    h4 {
      text-align: center;
    }

    .submitBtn {
      width: 100%;
      span {
        justify-content: center;
      }
    }
  }
  .headerLeft {
    h4 {
      font-weight: 400;
      color: rgba(0, 0, 0, 0.7);
      margin: 0.4rem 0;
      span {
        font-weight: 500;
      }
    }
  }
}
