.loader {
  display: block;
  height: 32px;
  width: 32px;
  position: relative;

  span {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    height: 32px;
    width: 32px;
  }
  span::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    height: 32px;
    width: 32px;
    border: 3px solid var(--clr-primary);
    border-bottom: 3px solid transparent;
    border-radius: 50%;
    -webkit-animation: loaderOuter 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
    animation: loaderOuter 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  }
  @-webkit-keyframes loaderOuter {
    0% {
      -webkit-transform: rotate(0deg);
    }
    40% {
      -webkit-transform: rotate(180deg);
    }
    60% {
      -webkit-transform: rotate(180deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes loaderOuter {
    0% {
      transform: rotate(0deg);
    }
    40% {
      transform: rotate(180deg);
    }
    60% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  span::after {
    content: "";
    position: absolute;
    top: 0;
    left: 5px;
    bottom: 0;
    right: 0;
    margin: auto;
    width: 6px;
    height: 6px;
    background: var(--clr-primary);
    border-radius: 50%;
    -webkit-animation: loaderInner 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
    animation: loaderInner 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  }
  @-webkit-keyframes loaderInner {
    0% {
      -webkit-transform: translate3d(0, -32px, 0) scale(0, 2);
      opacity: 0;
    }
    50% {
      -webkit-transform: translate3d(0, 0, 0) scale(1.25, 1.25);
      opacity: 1;
    }
    100% {
      -webkit-transform: translate3d(0, 8px, 0) scale(0, 0);
      opacity: 0;
    }
  }
  @keyframes loaderInner {
    0% {
      transform: translate3d(0, -32px, 0) scale(0, 2);
      opacity: 0;
    }
    50% {
      transform: translate3d(0, 0, 0) scale(1.25, 1.25);
      opacity: 1;
    }
    100% {
      transform: translate3d(0, 8px, 0) scale(0, 0);
      opacity: 0;
    }
  }
}

.center {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}
